import * as React from "react";
import {
  playerStore,
  entryStore,
  typesStore,
  categoryStore,
} from "../stores/stores";
import { WithId } from "../stores/Store";
import { Button, Popconfirm, Table } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import { appState } from "../appState";
import { ColumnsType } from "antd/lib/table";

type Entry = {
  date: firebase.firestore.Timestamp;
  amount: number;
  type: string;
  category: string;
};

type PlayerWithAmount = WithId<Backend.Player> & { entries: WithId<Entry>[], amount: number }

export const Players: React.FC = observer(() => {
  const { data: players } = playerStore.useCollection("players");
  const { data: entries } = entryStore.useCollection("entries");
  const { data: categories } = categoryStore.useCollection("categories");


  const playersMap: {
    [key: string]: {
      player: WithId<Backend.Player>;
      entries: WithId<Entry>[];
      amount: number;
    };
  } = {};

  if (!players || !entries || !categories) {
    return null;
  }

  entries.forEach((entry) => {
    entry.entries.forEach((entryEntry) => {
      const playerId = entryEntry.playerRef.id;
      const player = players.find((p) => p.id === playerId);
      const type = typesStore.getDocument(entryEntry.typeRef);
      const category =
        type.data?.categoryRef &&
        categoryStore.getDocument(type.data?.categoryRef);

      if (!player) {
        return;
      }

      if (!playersMap[playerId]) {
        playersMap[playerId] = { player, entries: [], amount: 0 };
      }

      if (type) {
        playersMap[playerId].amount += type.data?.amount || 0;
        playersMap[playerId].entries.push({
          id: entry.id,
          key: entry.key + type.data?.name,
          date: entry.date,
          amount: type.data?.amount || 0,
          type: type.data?.name ?? "",
          category: category?.data?.name ?? "",
        });
      }
    });
  });

  const playersWithAmount: PlayerWithAmount[] = players.map((player) => {
    const playerEntries = playersMap[player.id];

    if (!playerEntries) {
      return { ...player, entries: [], amount: 0 };
    }

    return {
      ...player,
      entries: playerEntries.entries,
      amount: playerEntries.amount,
    };
  });

  const columns: ColumnsType<PlayerWithAmount> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Böter",
      key: "entry",
      render: (_: unknown, player: PlayerWithAmount) => {
        return (playersMap[player.id]?.amount ?? 0) + " kr";
      },
    },
  ];

  if (appState.user) {
    columns.push({
      title: "",
      align: "right",
      key: "admin",
      render: (_: unknown, player: PlayerWithAmount) => {
        return (<Popconfirm
          title={"Är du säker på att du vill ta bort " + player.name + "?"}
          onConfirm={() => playerStore.delete(player.id)}
          okText="Ja"
          cancelText="Nej"
        >
          <Button danger>Ta bort</Button>
        </Popconfirm>)
      },
    })
  }

  const expandedRowRender = (
    record: PlayerWithAmount
  ) => {
    if (!entries.length) {
      return null;
    }

    const entryColumn: ColumnsType<WithId<Entry>> = [
      {
        title: "Datum",
        dataIndex: "date",
        key: "date",
        render: (_: unknown, entry: WithId<Entry>) => {
          return moment(entry.date.toDate()).format("YYYY-MM-DD");
        },
      },
      {
        title: "Tillfälle",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Typ",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Böter",
        key: "amount",
        render: (_: unknown, entry: WithId<Entry>) => {
          return entry.amount.toLocaleString() + " kr";
        },
      },
    ];

    if (appState.user) {
      entryColumn.push({
        title: "",
        align: "right",
        key: "admin",
        render: (_: unknown, entry: WithId<Entry>) => {
          return (<Popconfirm
            title={"Är du säker på att du vill ta bort " + record.name + "s böter?"}
            onConfirm={() => { entryStore.delete(entry.id); }}
            okText="Ja"
            cancelText="Nej"
          >
            <Button danger>Ta bort</Button>
          </Popconfirm>)
        },
      })
    }

    return (
      <Table
        columns={entryColumn}
        dataSource={record.entries}
        pagination={false}
        rowKey={"key"}
      />
    );
  };

  return (
    <Table
      sticky
      pagination={false}
      dataSource={playersWithAmount.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;

        return a.name.localeCompare(b.name);
      })}
      expandable={{
        expandedRowRender,
        rowExpandable: (row) => !!row.entries.length,
      }}
      columns={columns}
      summary={(data) => {
        const totalAmount = data.reduce((amount, player) => {
          return amount + (player.amount ?? 0);
        }, 0);

        return (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <strong>Totalt</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <Text>{totalAmount.toLocaleString() + " kr"}</Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
});
